* {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");


:root {
  --color-revival-orange-orange-1: #ffefe8;
  --color-revival-orange-orange-7: #ab2f00;
  --color-revival-orange-orange-6: #e43f00;
  --color-revival-orange-orange-4: #ff8d62;
  --color-revival-orange-orange-8: #721f00;
  --color-revival-orange-orange-5: #f05423;
  --color-revival-orange-orange-9: #561800;
  --color-revival-orange-orange-2: #ffcebc;
  --color-revival-orange-orange-3: #ffae8f;
  --color-revival-orange-orange-10: #391000;
  --color-revival-red-red-1: #f24949;
  --color-revival-mauve-mauve-1: #f0eef0;
  --color-revival-mauve-mauve-3: #b5acb5;
  --color-revival-mauve-mauve-2: #d2cdd3;
  --color-revival-mauve-mauve-5: #675c68;
  --color-revival-mauve-mauve-4: #978a98;
  --color-revival-mauve-mauve-7: #3e373e;
  --color-revival-mauve-mauve-6: #524a53;
  --color-revival-mauve-mauve-8: #29252a;
  --color-revival-mauve-mauve-9: #1d1c1f;
  --color-revival-mauve-mauve-10: #0a090a;
  --color-gray-gray-1: #fff;
  --color-gray-gray-4: #c2c2c2;
  --color-gray-gray-3: #d6d6d6;
  --color-gray-gray-2: #ebebeb;
  --color-gray-gray-6: #707070;
  --color-gray-gray-5: #999;
  --color-gray-gray-7: #474747;
  --color-gray-gray-9: #141414;
  --color-gray-gray-10: #050505;
  --color-gray-gray-11: #f9f9f9;
  --color-gray-gray-8: #2e2e2e;
  --color-system-blue-blue-2: #c4e5f3;
  --color-system-blue-blue-1: #ebf6fb;
  --color-system-blue-blue-3: #9cd3eb;
  --color-system-blue-blue-4: #61b9df;
  --color-system-blue-blue-6: #2589b5;
  --color-system-blue-blue-5: #39a7d7;
  --color-system-blue-blue-7: #1b6788;
  --color-system-blue-blue-9: #0e3344;
  --color-system-blue-blue-8: #12455b;
  --color-system-blue-blue-10: #09222d;
  --color-system-green-green-1: #e5fce8;
  --color-system-green-green-2: #b2f5b9;
  --color-system-green-green-4: #32e344;
  --color-system-green-green-3: #7fee8a;
  --color-system-green-green-5: #1ac12b;
  --color-system-green-green-6: #159a22;
  --color-system-green-green-7: #10741a;
  --color-system-green-green-8: #0d6116;
  --color-system-green-green-9: #0a4d11;
  --color-system-green-green-10: #052709;
  --color-system-red-red-1: #fee;
  --color-system-red-red-2: #ffcdcd;
  --color-system-red-red-3: #ffabab;
  --color-system-red-red-6: #ff1313;
  --color-system-red-red-5: #ff5757;
  --color-system-red-red-4: #ff8989;
  --color-system-red-red-7: #cd0000;
  --color-system-red-red-8: #890000;
  --color-system-red-red-9: #400;
  --color-system-red-red-10: #200;
  --color-system-yellow-yellow-1: #fff7ee;
  --color-system-yellow-yellow-2: #ffe7ca;
  --color-system-yellow-yellow-3: #ffd6a7;
  --color-system-yellow-yellow-4: #ffc684;
  --color-system-yellow-yellow-5: #ffae50;
  --color-system-yellow-yellow-6: #ff8f0d;
  --color-system-yellow-yellow-8: #864800;
  --color-system-yellow-yellow-7: #c96c00;
  --color-system-yellow-yellow-9: #653600;
  --color-system-yellow-yellow-10: #432400;
}

#root {
  font: 400 14px "Inter";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toastify-container{
  display: flex;
  align-items: center;
  gap: 10px;

}
.toastify-container ul {
	padding: 0;
	margin: 10px 0;
}
.toastify-container ul li {
	margin-bottom: 5px;
	list-style: none;
}

.Toastify__toast {
  background-color: #fff;
  color:#050505;
}

.Toastify__toast--success {
  border: 2px solid #1AC12B;
}
.Toastify__toast--error {
  border: 2px solid #FF5757;
}

.Toastify__close-button{
  color: #050505;
}
.icon-class {
  width: 90px;
  height: 90px;
  padding: 10px;
}
